import React from 'react'

const HeaderGeneric = (props) => (
    <header id="header">
        <h1>Mastered Software</h1>
        <p>Quality Software Development and Consulting</p>
    </header>
)

export default HeaderGeneric
