import { Link } from "gatsby";
import React from "react";

const Footer = props => (
  <footer id="footer">
    <section>
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/masteredsoft"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/astriarch/"
            className="icon fa-facebook alt"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/MasteredSoftware"
            className="icon fa-github alt"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">&copy; Mastered Software.</p>
  </footer>
);

export default Footer;
